/* TestimonialsSlider.css */

.testimonial-slider {
  display: flex;
  justify-content: center;
  width: 100%;

}

.testimonial-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 20px;
  max-width: 420px; 
  width: 90%; 
  margin: 0 auto;
}

.testimonial-card h3 {
  color: #2c462e;
  font-size: 24px; /* Increase the font size */
  margin-bottom: 20px; /* Increase the margin */
}

.testimonial-card p {
  color: #555;
  font-size: 18px; /* Increase the font size */
  line-height: 1.6; /* Adjust the line height */
}

.slick-dots li button:before {
  font-size: 12px; /* Increase the size of the dots */
  color: #5fd068;
}

.slick-prev,
.slick-next {
  font-size: 24px; /* Increase the size of the arrows */
  color: #5fd068;
}

@media (max-width: 768px) {
  .testimonial-card {
    padding: 15px;
  }

  .testimonial-card h3 {
    font-size: 20px; /* Adjust font size for smaller screens */
    margin-bottom: 15px; /* Adjust margin for smaller screens */
  }

  .testimonial-card p {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}
