.cntpage {
  background-color: #f6fbf4;
  height: fit-content;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.contact-info-section {
  background-color: #f6fbf4;
  width: 100%;
}

.info-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the start */
  width: 90%;
  max-width: 1280px; /* Ensure a max-width */
  margin: auto;
  padding: 50px 10px;
  flex-wrap: wrap;
  box-sizing: border-box; /* Add box-sizing for better control */
}

.left-contact, .right-contact {
  flex: 1;
  margin: 10px;
  box-sizing: border-box; /* Add box-sizing for better control */
}

.contact-heading {
  margin-bottom: 20px;
}

.contact-heading h1 {
  margin: 0;
  font-size: 45px;
  font-weight: 700;
  color: #193a1c;
}

.contact-icon {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.contact-icon img {
  width: 40px;
  height: 40px;
}

.address {
  margin-left: 20px;
}

.address h2 {
  margin: 0;
  font-size: 25px;
}

.address h3 {
  margin: 0;
  font-size: 20px;
  color: #5fd068;
}

.map-section {
  width: 100%;
  height: auto;
  padding: 50px 0;
  background-color: #f6fbf4;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; /* Add box-sizing for better control */
}

/* Media Queries */
@media (max-width: 1024px) {
  .contact-info-section {
    width: 100%;
  }

  .contact-heading h1 {
    font-size: 35px;
  }

  .address h2 {
    font-size: 22px;
  }

  .address h3 {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .contact-heading h1 {
    font-size: 30px;
  }

  .address h2 {
    font-size: 20px;
  }

  .address h3 {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .info-container {
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .left-contact, .right-contact {
    width: 100%;
    margin: 0;

  }
  .left-contact{
    margin: 15px 20px;
    padding: 5px;
  }

  .contact-heading h1 {
    font-size: 25px;
  }

  .address h2 {
    font-size: 18px;
  }

  .address h3 {
    font-size: 14px;
  }
}
