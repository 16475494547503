/* Navbar.css */
.navbar2 {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      max-width: calc(100vw - 0px);
      background: rgba(0, 125, 2, 1); 
      /* background: rgba(0, 0, 0, 0);  */
      border: none;
      outline: none;
      /* background: white; */
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9999;
      padding-top: 2px;
      padding-bottom: 2px;
    }

    .logo2 {
      width: auto;
      max-width: 300px;
      height: 70px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .logo2 img {
      max-width: 100%;
      height: auto;
    }
    
    /* Navigation menu styles (optional) */
    .nav-items2 {
      display: flex;
      list-style: none;
      text-align: center;
      justify-content: flex-end;
    }
    
    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.1rem;
      /* border: 2px solid white; */
    }
    
    .nav-item a {
      text-decoration: none;
      color: white;
      font-size: 1.1rem;
      font-family: 'Roboto';
      padding: 3px 10px;
      display: block;
      height: 100%;
      position: relative;
      transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    
    .nav-item a:after {
      content: "";
      position: absolute;
      width: 0%;
      height: 3px;
      display: block;
      transition: all 0.3s ease;
      bottom: 0%;
    }
    .nav-item a:hover::after{
      width: 100%;
      height: 2px;
      /* background-color: #00B050; */
      background-color: #fff;
    }