.footer {
  background-color: #282c34;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer-logo {
  width: 200px; /* Adjust size for smaller screens */
  margin-bottom: 10px;
  overflow-y: hidden;
}

.footer-links,
.footer-social {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.footer-links a,
.footer-social a {
  color: white;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.footer-links a:hover,
.footer-social a:hover {
  color: #5fd068;
  transform: scale(1.1);
}

.footer-bottom {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 12px; /* Adjust font size for smaller screens */
}

/* Media Queries */
@media (max-width: 768px) {
  .footer-content {
    gap: 10px;
  }

  .footer-logo {
    width: 150px; /* Adjust size for smaller screens */
  }

  .footer-links,
  .footer-social {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .footer-links,
  .footer-social {
    gap: 0;
  }

  .footer-links a,
  .footer-social a {
    font-size: 12px; /* Adjust font size for smaller screens */
  }
}
