.msg-page {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  background-color: #f6fbf4;
  /* @import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap"); */
}
.navbar-section {
  width: 100%;
}
.msg-hero-section {
  width: 100%;
  height: auto;
  position: relative;
}
.msg-img {
  width: 100%;
  position: relative;
}
.msg-img img {
  width: 100%;
  height: calc(100vh - 300px);
  object-fit: cover;
  display: block;
}
.msg-img .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
}
.msg-hero-section h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 1;
  margin: 0;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}

.msg-hero-section h1 span {
  color: #5fd068;
}
.msg-sub-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1280px;
  margin: auto;
  padding: 50px 10px;
  margin-bottom: 45px;
  margin-top: 30px;
}
.msg-chairman {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
}
.msg-chairman .right {
  width: 45%;
  height: 100%;
}
.msg-chairman .left {
  width: 45%;
  height: 100%;
  margin-top: 100px;
}
.msg-chairman .right img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 5px;
}
.msg-chairman .left h1 {
  font-size: 45px;
  font-weight: 700;
}
.msg-chairman .left h1 span {
  font-size: 46px;
  color: #5fd068;
}
.quote-section {
  background-color: #ddeedf;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  /* font-family: "Dancing Script", cursive; */
  color: #005271;
  text-align: center;
}
.quote-section h1 span {
  color: #5fd068;
}
.flag-section {
  width: 100%;
  height: auto;
  margin-top: 30px;
  background-color: #ddeedf;
}
.country-flags {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.flag {
  width: 100px;
  margin-right: 10px;
}
.testimonials-section{
  padding: 50px 0;
  background-color: #ddeedf;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 100px;
}
.testimonials-section h1{
  font-size: 50px;
  color: #121212;
}
/* Media Queries */
@media (max-width: 1024px) {
  .msg-img img {
    height: calc(100vh - 200px);
  }
  .msg-hero-section h1 {
    font-size: 40px;
  }
  .msg-sub-section {
    width: 90%;
    flex-direction: column-reverse;
    gap: 50px;
    padding: 20px;
  }
  .msg-chairman .left {
    margin-top: 0;
  }
  .msg-chairman .right,
  .msg-chairman .left {
    width: 100%;
  }
  .msg-chairman .left h1 {
    font-size: 35px;
  }
  .msg-chairman .left h1 span {
    font-size: 36px;
  }
  .msg-chairman .right img {
    height: auto;
  }
  .quote-section {
    padding: 50px;
  }
}

@media (max-width: 768px) {
  .msg-img img {
    height: 60vh;
  }
  .msg-hero-section h1 {
    font-size: 30px;
  }
  .msg-chairman .left h1 {
    font-size: 28px;
  }
  .msg-chairman .left h1 span {
    font-size: 29px;
  }
  .msg-chairman .right img {
    height: auto;
  }
  .quote-section {
    padding: 30px;
    font-size: 14px;
  }
  .flag-section {
    display: none;
  }
}

@media (max-width: 480px) {
  .msg-img img {
    height: 40vh;
  }
  .msg-hero-section h1 {
    font-size: 25px;
    text-align: center;
    width: 80%;
  }
  .msg-sub-section {
    width: 95%;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 30px;
  }
  .msg-chairman {
    flex-direction: column;
    align-items: center;
  }
  .msg-chairman .right,
  .msg-chairman .left {
    width: 100%;
    margin-top: 0;
  }
  .msg-chairman .right img {
    height: auto;
    width: 100%;
  }
  .msg-chairman .left h1 {
    font-size: 28px;
    margin: 5px 0px;
  }
  .msg-chairman .left h1 span {
    font-size: 28px;
  }
  .msg-chairman .left p {
    font-size: 18px;
    margin: 10px 0px;
  }
  .quote-section {
    padding: 20px;
    font-size: 12px;
  }
}
