.aboutpage {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  background-color: #f6fbf4;
}

.navbar-section {
  width: 100%;
}

.about-hero-section {
  width: 100%;
  height: auto;
  position: relative;
}

.abt-img {
  width: 100%;
  position: relative;
}

.abt-img img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
  display: block;
}

.abt-img .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.abt-img .overlay h1 {
  display: flex;
  color: #f6fbf4;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  padding: 0 10px;
}

.abt-img .overlay h1 span {
  color: #5fd068;
  margin-left: 15px;
}

.abt-sub-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  margin: auto;
  padding: 10px 10px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.bragging {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.number-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.number-count h1 {
  color: #5fd068;
  font-size: 1.5rem;
}

.number-count h3 {
  color: #2c462e;
  font-size: 1rem;
}

.abt-sub-section-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  margin: auto;
  margin-bottom: 70px;
  margin-top: 40px;
  text-align: center;
}

.abt-heading {
  width: 100%;
}

.abt-heading h1 {
  font-size: 2.5rem;
  color: #2c462e;
}

.abt-sub-section-text {
  width: 100%;
  font-size: 1rem;
  margin-top: 15px;
}
.load-more-btn {
  display: inline-block;
  padding: .75rem 1.25rem;
  border-radius: 3px; 
  color: #00B050;
  font-size: 18px;
  transition: all .5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0); 
  margin-top: 20px;
  border: 1px solid #00B050;
  outline: none;
}
.load-more-btn > svg {
  vertical-align: middle;
  margin-left: 5px;
}
.load-more-btn:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0); 
  border-radius: 3px; 
  z-index: -2;
}

.load-more-btn:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #00B050;
  transition: all .5s;
  border-radius: 3px; 
  z-index: -1;
}

.load-more-btn:hover {
  color: #fff;
}

.load-more-btn:hover:before {
  width: 100%;
}
h1::selection {
  background-color: #5fd068;
}

p::selection {
  background-color: #5fd068;
}

h1 span::selection {
  background-color: #fbf4;
}

.content {
  height: 40vh;
}

.abt-licens-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  width: 90vw;
  margin: auto;
  margin-bottom: 70px;
  margin-top: 40px;
  text-align: center;
}

.abt-licens-section h1 {
  font-size: 2.5rem;
}

.abt-licens-img {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
  align-items: center;
  width: 90vw;
}

.img1,
.img2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.abt-licens-img img {
  width: 100%;
  max-width: 500px;
  object-fit: cover;
}

.abt-partners-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 20px auto;
  padding-bottom: 30px;
  width: 90vw;
}

.abt-partners-section h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

h1 span {
  color: #5fd068;
}
.abt-table-section {
  width: 70vw;
  margin: auto;
  margin-bottom: 70px;
  margin-top: 40px;
  text-align: center;
}

.table-heading {
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.table th {
  background-color: #2c462e;
  color: white;
}

.table td {
  background-color: #f6fbf4;
}

@media (min-width: 768px) {
  .abt-img img {
    height: 70vh;
  }

  .countup {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .number-count h1 {
    font-size: 3rem;
  }

  .number-count h3 {
    font-size: 1.5rem;
  }

  .abt-sub-section-2 {
    flex-direction: row;
    text-align: left;
  }

  .abt-heading {
    width: 50%;
  }

  .abt-sub-section-text {
    width: 45%;
  }

  .abt-licens-img {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .img1,
  .img2 {
    flex-direction: row;
  }

  .abt-licens-img img {
    width: calc(50% - 10px);
  }
  .table th,
  .table td {
    padding: 8px;
  }

  .table th {
    font-size: 1rem;
  }

  .table td {
    font-size: 0.9rem;
  }

}

@media (min-width: 1024px) {
  .abt-img img {
    height: calc(100vh - 400px);
  }

  .abt-img .overlay h1 {
    font-size: 4rem;
  }

  .abt-sub-section {
    flex-direction: row;
  }

  .abt-heading h1 {
    font-size: 4.5rem;
  }

  .number-count h1 {
    font-size: 3.5rem;
  }

  .number-count h3 {
    font-size: 1.8rem;
  }

}
@media (max-width: 767px) {
  .table-heading h1 {
    font-size: 1.8rem; /* Adjust the font size as needed for mobile */
  }
}