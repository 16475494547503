.carousel-container {
  width: 80vw;
  margin: 0 auto;
}

.carousel-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel-image {
  width: 100px;
  height: 100px; /* Ensure all images are the same height */
  object-fit: cover; /* Ensure the image covers the entire area */
}

@media (min-width: 1024px) {
  .carousel-image {
    width: 150px;
    height: 150px; /* Ensure all images are the same height */
  }
}

@media (max-width: 768px) {
  .carousel-container {
    width: 90vw;
  }
  .carousel-image {
    width: 120px;
    height: 120px; /* Ensure all images are the same height */
  }
}

@media (max-width: 480px) {
  .carousel-container {
    width: 95vw;
  }
  .carousel-image {
    width: 100px;
    height: 100px; /* Ensure all images are the same height */
  }
}
