/* General navbar styles */
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 5px 20px;
  transition: background 0.5s ease-in-out;
}

.navbar.fixed {
  /* background: rgb(2, 156, 5); */
  background: linear-gradient(90deg, hsla(121, 97%, 31%, 1) 10%, hsla(119, 37%, 45%, 1) 100%);
}

/* Logo styles */
.logo {
  width: auto;
  max-width: 300px;
  height: 70px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  max-width: 100%;
  height: auto;
}

/* Menu icon styles */
.menu-icon {
  display: none;
  font-size: 1.8rem;
  color: white;
  cursor: pointer;
  margin: 0px 35px;
}

/* Navigation menu styles */
.nav-items {
  display: flex;
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
}

.nav-items li {
  margin-right: 1.1rem;
}

.nav-items a {
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
  font-family: "Roboto";
  padding: 3px 10px;
  display: block;
  position: relative; /* Needed for the underline effect */
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.nav-items a::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 3px;
  display: block;
  transition: all 0.3s ease;
  bottom: 0%;
}

.nav-items a:hover::after {
  width: 100%;
  height: 2px;
  background-color: #fff;
}

/* Active link styles */
.nav-items a.active::after {
  width: 100%;
  height: 2px;
  background-color: #fff;
}

/* Responsive styles */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
    z-index: 9999;
  }
  .navbar {
    padding: 0px 20px;
  }
  .nav-items {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 300px;
    background-color: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: right 0.3s ease-in-out;

  }

  .nav-items.active {
    right: 0;
  }

  .nav-items li {
    margin: 20px 0;
  }

  .nav-items a {
    font-size: 1.5rem;
  }
  .logo{
    width: auto;
    max-width: 250px;
    height: 70px;
    padding: 0;
  }

}
