.hero-container {
  width: 100%;
  height: auto;
  max-width: calc(100vw - 0px);
  margin: 0px;
}
.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 999;
  width: 100%;
}
.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99.5%;
  background-color: rgba(0, 0, 0, 0.05);
}
.hero-slider {
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
}
.hero-slide {
  width: 100%;
  height: 100%;
}
.hero-slide img {
  width: 100%;
  height: calc(100vh - 0px);
  object-fit: cover;
}
.hero-btn {
  display: inline-block;
  padding: .75rem 1.25rem;
  border-radius: 3px; 
  color: #fff;
  font-size: 18px;
  transition: all .5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0); 
  margin-top: 20px;
  border: 1px solid #fff;
  outline: none;
}
.hero-btn > svg {
  vertical-align: middle;
  margin-left: 5px;
}
.hero-btn:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0); 
  border-radius: 3px; 
  z-index: -2;
}

.hero-btn:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #00B050;
  transition: all .5s;
  border-radius: 3px; 
  z-index: -1;
}

.hero-btn:hover {
  color: #fff;
}

.hero-btn:hover:before {
  width: 100%;
}

.hero-text h1{
  font-size: 70px;
  text-transform: capitalize;
  color: #01cf5e;
  margin: 0;
}
.hero-text p{
  font-size: 65px;
  color: #fff;
  font-weight: 600;
  margin: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 40px;
  }
  .hero-text p {
    font-size: 30px;
  }
  .hero-btn {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .hero-text h1 {
    font-size: 30px;
  }
  .hero-text p {
    font-size: 20px;
  }
  .hero-btn {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
}