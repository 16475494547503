
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  overflow-y: scroll; /* Enable scrolling for the body */
}

::-webkit-scrollbar {
  width: 10px; /* Scrollbar width */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background color */
}

::-webkit-scrollbar-thumb {
  background: #5fd068; /* Thumb background color */
  /* border-radius: 5px;  */
}

::-webkit-scrollbar-thumb:hover {
  background: #47c452; /* Hover effect for thumb */
}