.gallery {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  background-color: #f6fbf4;
}

.gallery-hero-section {
  width: 100%;
  height: auto;
  position: relative;
}

.glry-img {
  width: 100%;
  position: relative;
}

.glry-img img {
  width: 100%;
  height: calc(100vh - 400px);
  object-fit: cover;
  display: block;
}

.glry-img .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.glry-img .overlay h1 {
  color: #f6fbf4;
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
}

.glry-img .overlay h1 span {
  color: #5fd068;
  margin-right: 18px;
}

.glry-1 {
  width: 100%;
  max-width: 1280px;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.glry-1 h1,
.glry-2 h1,
.glry-3 h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #2c462e;
}

.office-img {
  width: 100%;
  height: 280px;
  overflow: hidden;
  border-radius: 5px;
}

.office-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.5s ease;
}

.office-img img:hover {
  transform: scale(1.1);
}

.glry-2 {
  width: 100%;
  max-width: 1280px;
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #f6fbf4;
}

.glry-3 {
  width: 100%;
  max-width: 1280px;
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #f6fbf4;
}

.gg2 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

@media (max-width: 1024px) {
  .glry-img img {
    height: calc(100vh - 300px);
  }

  .glry-1,
  .gg2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 640px) {
  .glry-img img {
    height: calc(100vh - 380px);
  }

  .glry-img .overlay h1 {
    font-size: 2.5rem;
  }

  .glry-1,
  .gg2 {
    grid-template-columns: 1fr;
  }
}
