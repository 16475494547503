.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box; /* Add box-sizing for better control */
}

.form-container h1 {
  font-size: 2rem;
  padding: 5px;
  text-align: center;
}

.input-field {
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 5px;
  width: 100%;
  box-sizing: border-box; /* Add box-sizing for better control */
}

.input-field input,
textarea {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 500px;
  font-size: 1rem;
  box-sizing: border-box; /* Add box-sizing for better control */
}

label {
  font-size: 1rem;
  font-weight: 600;
}

.submit-btn {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(
    90deg,
    hsla(121, 97%, 31%, 1) 10%,
    hsla(119, 37%, 45%, 1) 100%
  );
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
  margin-top: 10px;
}

.submit-btn:hover {
  background: linear-gradient(
    90deg,
    hsla(125, 55%, 59%, 1) 10%,
    hsla(119, 37%, 45%, 1) 100%
  );
  color: white;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: -5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .form-container {
    padding: 20px;
  }

  .input-field input,
  textarea {
    font-size: 1rem;
    max-width: 80vw;
  }

  .submit-btn {
    font-size: 1rem;
    padding: 10px 15px;
  }
}

@media (max-width: 480px) {
  .form-container h1 {
    font-size: 1.5rem;
  }

  .input-field input,
  textarea {
    font-size: 0.9rem;
  }

  .submit-btn {
    font-size: 0.9rem;
    padding: 10px 10px;
  }
}
