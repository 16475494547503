.homepage {
  width: 100%;
  max-width: calc(100vw - 0px);
  overflow: hidden;
  background-color: #f6fbf4;
}
.hero-section {
  width: 100%;
  height: auto;
}
.navbar-section {
  width: 100%;
}

.sub-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1280px;
  margin: auto;
  padding: 50px 10px;
  margin-bottom: 65px;
  margin-top: 50px;
}
.left-col {
  width: 55%;
}

.right-col {
  width: 40%;
}

.left-col h1 span {
  font-size: 50px;
  color: #00b050;
  color: #5fd068;
}
.left-col h1 {
  font-size: 50px;
  color: #121212;
}

.left-col p {
  font-size: 16px;
  margin: 10px;
  color: #353535;
  margin-right: 30px;
}

.right-col img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 5px;
}
.info-item {
  display: flex;
  align-items: center;
  mix-blend-mode: multiply;
  background-color: #f6fbf4;
}

.info-item img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  mix-blend-mode: multiply;
  background-color: #f6fbf4;
}
.info-item p {
  font-weight: 600;
  font-size: 18px;
}

.flag-section {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 30px;
  background-color: #ddeedf;
}
.country-flags {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.flag {
  width: 100px;
  margin-right: 10px;
}

.sub-section-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1280px;
  margin: 50px auto;
}
.sub-section-2 h1 {
  font-size: 50px;
  color: #121212;
}
.sub-section-2 h1 span {
  font-size: 50px;
  color: #5fd068;
}

.sectors-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 50px 10px;
  gap: 30px;
}
.sector-item {
  width: 300px;
  height: 300px;
  transition: 0.4s ease-in-out;
}
.sector-item img {
  width: 100%;
  height: 100%;
}
.sector-item:hover {
  transform: scale(1.05);
}
.sub-section-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1280px;
  margin: 100px auto;
}
.sub-section-3 h1 {
  font-size: 50px;
  color: #121212;
}
.sub-section-3 h1 span {
  font-size: 50px;
  color: #5fd068;
}
.map-2{
  height: 400px;
  width: 100%;
  max-width: 90vw;
  margin-bottom: 20px;
  overflow: hidden;
}
.map-2 iframe {
  width: 100%;
  height: 400px;
  border: 0;
}
/* Media Queries */
@media (max-width: 1024px) {
  .sub-section {
    width: 90%;
    flex-direction: column-reverse;
    gap: 50px;
    padding: 20px;
  }
  .left-col,
  .right-col {
    width: 100%;
  }
  .left-col h1 {
    font-size: 40px;
  }
  .left-col h1 span {
    font-size: 40px;
  }
  .left-col p {
    font-size: 14px;
  }
  .right-col img {
    height: auto;
  }
  .sub-section-2,
  .sub-section-3 {
    width: 90%;
  }
  .sub-section-2 h1,
  .sub-section-3 h1 {
    font-size: 40px;
  }
  .sub-section-2 h1 span,
  .sub-section-3 h1 span {
    font-size: 40px;
  }
  .sectors-container {
    grid-template-columns: 1fr 1fr;
  }
  .sector-item {
    width: 100%;
    height: auto;
  }
  .map-2 iframe {
    height: 300px;
  }
}

@media (max-width: 768px) {
  .country-flags {
    flex-direction: column;
  }
  .flag {
    width: 80px;
    margin-bottom: 10px;
  }
  .sub-section-2 h1,
  .sub-section-3 h1 {
    font-size: 30px;
  }
  .sub-section-2 h1 span,
  .sub-section-3 h1 span {
    font-size: 30px;
  }
  .sectors-container {
    grid-template-columns: 1fr;
  }
  .map-2 iframe {
    height: 250px;
  }
}

@media (max-width: 480px) {
  .left-col h1,
  .sub-section-2 h1,
  .sub-section-3 h1 {
    font-size: 28px;
  }
  .left-col h1 span,
  .sub-section-2 h1 span,
  .sub-section-3 h1 span {
    font-size: 28px;
  }
  .left-col p {
    font-size: 18px;
  }
  .map{
    height: 250px;
    width: 100%;
    max-width: 90vw;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .map img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .map-2 iframe {
    height: 200px;
  }
  .flag-section{
    display: none;
  }
}
